/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Alert from "../../shared/react/Alert.jsx";

const ordinalNames = ["Primary", "Secondary", "Tertiary"];

export default async function confirmSubscriberInfo(confirm, data) {
  // Make an array of all the info discrepancies for each insurance marked as "Self"
  const subscriberDetails = data.insurance.map((i, index) =>
    [
      {
        name: "subscriberName",
        label: "Name",
        priority: index,
        value: i.subscriberName,
        patientInfo: data.patientName,
        relation: i.relationToPatient,
      },
      {
        name: "subscriberAddress",
        label: "Address",
        priority: index,
        value: i.subscriberAddress,
        patientInfo: data.patientAddress,
        relation: i.relationToPatient,
      },
      {
        name: "subscriberSex",
        label: "Sex Designated At Birth",
        priority: index,
        value: i.subscriberSex,
        patientInfo: data.patientGender,
        relation: i.relationToPatient,
      },
      {
        name: "subscriberDob",
        label: "Date of Birth",
        priority: index,
        value: i.subscriberDob?.toLocaleString(),
        patientInfo: data.patientDob?.toLocaleString(),
        relation: i.relationToPatient,
      },
    ].filter(
      (detailsObject) =>
        detailsObject?.relation === "Self" && detailsObject.value !== detailsObject.patientInfo
    )
  );

  if (subscriberDetails.flat().length > 0) {
    // Display the subscriber info discrepancies to the user
    await confirm({
      title: "Confirm Subscriber Details Marked as Self",
      content: <SubscriberInfoConfirmation subscriberDetails={subscriberDetails} />,
      confirmationText: "Continue",
      cancellationText: "Back",
    });
  }
}

function SubscriberInfoConfirmation({subscriberDetails}) {
  return (
    <Grid container data-cy="subscriber-info-confirmation-dialog">
      <Alert
        message="We found subscriber information that differs from patient information. If the details below are correct, click Continue."
        level="warning"
        otherProps={{
          "data-cy": "subscriber-info-confirmation-message",
        }}
      />
      {subscriberDetails.map(
        (array, index) =>
          array.length > 0 && (
            <Grid container key={ordinalNames[index]} sx={{mt: 2}}>
              <Typography variant="h6" data-cy={`subscriber-info-header-${index}`}>
                {ordinalNames[index]} Insurance
              </Typography>

              {array.map((detail) => {
                return (
                  <Grid size={12} sx={{mb: 1}} key={`${detail.name}-${detail.priority}`}>
                    <Typography
                      variant="subtitle1"
                      sx={{fontWeight: "medium"}}
                      data-cy={`${detail.label}-label-${index}`}
                    >
                      &ensp;{detail.label}
                    </Typography>
                    <Grid data-cy={`patient-${detail.label}-detail-${index}`}>
                      &emsp;Patient: &emsp;&emsp; {detail.patientInfo || <i>Empty</i>}
                    </Grid>
                    <Grid data-cy={`subscriber-${detail.label}-detail-${index}`}>
                      &emsp;Subscriber: &ensp; {detail.value || <i>Empty</i>}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )
      )}
    </Grid>
  );
}
SubscriberInfoConfirmation.propTypes = {
  subscriberDetails: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        priority: PropTypes.number,
        label: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
      })
    )
  ).isRequired,
};
