const languages = [
  {name: "English", code: "en"},
  {name: "Abkhazian", code: "ab"},
  {name: "Afar", code: "aa"},
  {name: "Afrikaans", code: "af"},
  {name: "Akan", code: "ak"},
  {name: "Albanian", code: "sq"},
  {name: "Amharic", code: "am"},
  {name: "Arabic", code: "ar"},
  {name: "Aragonese", code: "an"},
  {name: "Armenian", code: "hy"},
  {name: "Assamese", code: "as"},
  {name: "Avar", code: "av"},
  {name: "Aymara", code: "ay"},
  {name: "Azerbaijani", code: "az"},
  {name: "Bambara", code: "bm"},
  {name: "Bashkir", code: "ba"},
  {name: "Basque", code: "eu"},
  {name: "Belarusian", code: "be"},
  {name: "Bengali", code: "bn"},
  {name: "Bihari", code: "bh"},
  {name: "Bislama", code: "bi"},
  {name: "Bosnian", code: "bs"},
  {name: "Breton", code: "br"},
  {name: "Bulgarian", code: "bg"},
  {name: "Burmese", code: "my"},
  {name: "Cambodian", code: "km"},
  {name: "Catalan", code: "ca"},
  {name: "Chamorro", code: "ch"},
  {name: "Chechen", code: "ce"},
  {name: "Chichewa", code: "ny"},
  {name: "Chinese", code: "zh"},
  {name: "Chuvash", code: "cv"},
  {name: "Cornish", code: "kw"},
  {name: "Corsican", code: "co"},
  {name: "Cree", code: "cr"},
  {name: "Croatian", code: "hr"},
  {name: "Czech", code: "cs"},
  {name: "Danish", code: "da"},
  {name: "Divehi", code: "dv"},
  {name: "Dutch", code: "nl"},
  {name: "Dzongkha", code: "dz"},
  {name: "Esperanto", code: "eo"},
  {name: "Estonian", code: "et"},
  {name: "Ewe", code: "ee"},
  {name: "Faroese", code: "fo"},
  {name: "Fijian", code: "fj"},
  {name: "Finnish", code: "fi"},
  {name: "French", code: "fr"},
  {name: "Galician", code: "gl"},
  {name: "Ganda", code: "lg"},
  {name: "German", code: "de"},
  {name: "Greek", code: "el"},
  {name: "Greenlandic", code: "kl"},
  {name: "Guarani", code: "gn"},
  {name: "Gujarati", code: "gu"},
  {name: "Haitian", code: "ht"},
  {name: "Hausa", code: "ha"},
  {name: "Hebrew", code: "he"},
  {name: "Herero", code: "hz"},
  {name: "Hindi", code: "hi"},
  {name: "Hiri Motu", code: "ho"},
  {name: "Hungarian", code: "hu"},
  {name: "Icelandic", code: "is"},
  {name: "Ido", code: "io"},
  {name: "Igbo", code: "ig"},
  {name: "Indonesian", code: "id"},
  {name: "Interlingua", code: "ia"},
  {name: "Interlingue", code: "ie"},
  {name: "Inuktitut", code: "iu"},
  {name: "Inupiak", code: "ik"},
  {name: "Irish", code: "ga"},
  {name: "Italian", code: "it"},
  {name: "Japanese", code: "ja"},
  {name: "Javanese", code: "jv"},
  {name: "Kannada", code: "kn"},
  {name: "Kanuri", code: "kr"},
  {name: "Kashmiri", code: "ks"},
  {name: "Kazakh", code: "kk"},
  {name: "Kikuyu", code: "ki"},
  {name: "Kirghiz", code: "ky"},
  {name: "Kirundi", code: "rn"},
  {name: "Komi", code: "kv"},
  {name: "Kongo", code: "kg"},
  {name: "Korean", code: "ko"},
  {name: "Kuanyama", code: "kj"},
  {name: "Kurdish", code: "ku"},
  {name: "Laotian", code: "lo"},
  {name: "Latin", code: "la"},
  {name: "Latvian", code: "lv"},
  {name: "Limburgian", code: "li"},
  {name: "Lingala", code: "ln"},
  {name: "Lithuanian", code: "lt"},
  {name: "Luxembourgish", code: "lb"},
  {name: "Macedonian", code: "mk"},
  {name: "Malagasy", code: "mg"},
  {name: "Malay", code: "ms"},
  {name: "Malayalam", code: "ml"},
  {name: "Maltese", code: "mt"},
  {name: "Manx", code: "gv"},
  {name: "Maori", code: "mi"},
  {name: "Marathi", code: "mr"},
  {name: "Marshallese", code: "mh"},
  {name: "Moldovan", code: "mo"},
  {name: "Mongolian", code: "mn"},
  {name: "Nauruan", code: "na"},
  {name: "Navajo", code: "nv"},
  {name: "Ndonga", code: "ng"},
  {name: "Nepali", code: "ne"},
  {name: "North Ndebele", code: "nd"},
  {name: "Norwegian", code: "no"},
  {name: "Norwegian Nynorsk", code: "nn"},
  {name: "Occitan", code: "oc"},
  {name: "Ojibwa", code: "oj"},
  {name: "Old Church Slavonic / Old Bulgarian", code: "cu"},
  {name: "Oriya", code: "or"},
  {name: "Oromo", code: "om"},
  {name: "Ossetian", code: "os"},
  {name: "Pali", code: "pi"},
  {name: "Pashto", code: "ps"},
  {name: "Persian", code: "fa"},
  {name: "Peul", code: "ff"},
  {name: "Polish", code: "pl"},
  {name: "Portuguese", code: "pt"},
  {name: "Punjabi", code: "pa"},
  {name: "Quechua", code: "qu"},
  {name: "Raeto Romance", code: "rm"},
  {name: "Romanian", code: "ro"},
  {name: "Russian", code: "ru"},
  {name: "Rwandi", code: "rw"},
  {name: "Samoan", code: "sm"},
  {name: "Sango", code: "sg"},
  {name: "Sanskrit", code: "sa"},
  {name: "Sardinian", code: "sc"},
  {name: "Scottish Gaelic", code: "gd"},
  {name: "Serbian", code: "sr"},
  {name: "Serbo-Croatian", code: "sh"},
  {name: "Shona", code: "sn"},
  {name: "Sichuan Yi", code: "ii"},
  {name: "Sindhi", code: "sd"},
  {name: "Sinhalese", code: "si"},
  {name: "Slovak", code: "sk"},
  {name: "Slovenian", code: "sl"},
  {name: "Somalia", code: "so"},
  {name: "South Ndebele", code: "nr"},
  {name: "Southern Sotho", code: "st"},
  {name: "Spanish", code: "es"},
  {name: "Sundanese", code: "su"},
  {name: "Swahili", code: "sw"},
  {name: "Swati", code: "ss"},
  {name: "Swedish", code: "sv"},
  {name: "Tagalog", code: "tl"},
  {name: "Tahitian", code: "ty"},
  {name: "Tajik", code: "tg"},
  {name: "Tamil", code: "ta"},
  {name: "Tatar", code: "tt"},
  {name: "Telugu", code: "te"},
  {name: "Thai", code: "th"},
  {name: "Tibetan", code: "bo"},
  {name: "Tigrinya", code: "ti"},
  {name: "Tonga", code: "to"},
  {name: "Tsonga", code: "ts"},
  {name: "Tswana", code: "tn"},
  {name: "Turkish", code: "tr"},
  {name: "Turkmen", code: "tk"},
  {name: "Twi", code: "tw"},
  {name: "Urdu", code: "ur"},
  {name: "Uyghur", code: "ug"},
  {name: "Venda", code: "ve"},
  {name: "Vietnamese", code: "vi"},
  {name: "Volapük", code: "vo"},
  {name: "Walloon", code: "wa"},
  {name: "Welsh", code: "cy"},
  {name: "West Frisian", code: "fy"},
  {name: "Wolof", code: "wo"},
  {name: "Xhosa", code: "xh"},
  {name: "Yiddish", code: "yi"},
  {name: "Yoruba", code: "yo"},
  {name: "Zhuang", code: "za"},
  {name: "Zulu", code: "zu"},
];

export default languages;
